<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field
              :rules="requestField"
              v-model="form.username"
              color="blue-grey lighten-2"
              label="Username"
              required
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <v-text-field
              type="password"
              v-model="form.password"
              :rules="requestField"
              color="blue-grey lighten-2"
              label="Password"
              required
              outlined
              @keypress.enter="singIn"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <v-text-field
              :rules="requestField"
              v-model="form.fio"
              color="blue-grey lighten-2"
              label="FIO"
              required
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <v-text-field
              :rules="requestField"
              v-model="form.phoneNumber"
              color="blue-grey lighten-2"
              label="Phone Number"
              required
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <v-select
              v-model="form.resetPasswordQuestionId"
              :rules="requestField"
              :items="questions"
              label="Question"
              item-text="value"
              item-value="id"
              outlined
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <v-text-field
              :rules="requestField"
              v-model="form.resetPasswordQuestionAnswer"
              color="blue-grey lighten-2"
              label="Phone Number"
              required
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <v-select
              v-model="form.chosenLanguage"
              :rules="requestField"
              :items="languages"
              label="Question"
              item-text="value"
              item-value="id"
              outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
            :loading="loading"
            :disabled="loading"
            color="blue accent-4"
            class="ma-2 white--text"
            @click="register()"
        >Register<v-icon right dark>mdi-login</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import TokenService from "../../../services/TokenService";

export default {
  name: "FormRegister",
  data() {
    return {
      valid: false,
      loading: false,
      form: {
        username: '',
        password: '',
        fio: '',
        phoneNumber: '',
        resetPasswordQuestionId: 1,
        resetPasswordQuestionAnswer: '',
        chosenLanguage: ''
      },
      questions: [
        {
          id: 1,
          value: 'Girl-Surname'
        }
      ],
      languages: [

      ]
    }
  },
  methods: {
    async register() {
      await this.validate();
      if (this.valid) {
        this.loading = true;
        try {
          const res = await this.$http.post('/api-authUser/registerUser', this.form);
          console.log(res)
        } catch (e) {
          this.errorNotification(e.response.data.message)
        } finally {
          this.loading = false;
        }
      }
    },
    validate() {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style scoped>

</style>