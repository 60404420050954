<template>
  <v-form
      ref="loginForm"
      v-model="valid"
      lazy-validation>
    <v-container>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field
              :rules="requestField"
              v-model="username"
              color="blue-grey lighten-2"
              label="Username"
              required
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <v-text-field
              type="password"
              v-model="password"
              :rules="requestField"
              color="blue-grey lighten-2"
              label="Password"
              required
              outlined
              @keypress.enter="singIn"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
            :loading="loading"
            :disabled="loading"
            color="blue accent-4"
            class="ma-2 white--text"
            @click="singIn"
        >Login<v-icon right dark>mdi-login</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import TokenService from "../../../services/TokenService";

export default {
  name: "FormLogin",
  data() {
    return {
      valid: false,
      loading: false,
      username: '',
      password: '',
    }
  },
  methods: {
    async singIn() {
      await this.validate();
      if (this.valid) {
        this.loading = true;
        try {
          const res = await this.$http.post('/api-authUser/login', {
            username: this.username,
            password: this.password
          })
          TokenService.setToken(res.result.accessToken)
          TokenService.setRefreshToken(res.result.refreshToken)
          TokenService.setExpireDate(res.result.expireDate)
          await this.$store.dispatch('getPermissions');
          await this.$router.push({ path: '/'});
        } catch (e) {
          this.errorNotification(e.response.data.message)
        } finally {
          this.loading = false;
        }
      }
    },
    validate() {
      this.$refs.loginForm.validate()
    },
    reset () {
      this.$refs.loginForm.reset()
    },
    resetValidation () {
      this.$refs.loginForm.resetValidation()
    },
  },
}
</script>

<style scoped>

</style>