<template>
  <v-app>
    <v-main style="background-color: rgba(54,78,101,.8)">
      <v-row justify="center" align="center" class="w-100 h-100">
        <v-col cols="11" md="5">
          <v-card light>
            <v-img
                height="200"
                src="img/banner-1.png">
              <v-row class="h-100 pa-6" align="center" justify="center">
                <v-col class="pl-6">
                  <h3 class="headline white--text text-h3">KK Project</h3>
                  <span class="grey--text text--lighten-1">Admin panel</span>
                </v-col>
              </v-row>
            </v-img>
            <v-tabs
                v-model="tab"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                  v-for="item in items"
                  :key="item"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <form-login />
              </v-tab-item>
              <v-tab-item>
                <form-register />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import TokenService from "../../services/TokenService";
import store from "../../store";
import FormLogin from "./components/FormLogin";
import FormRegister from "./components/FormRegister";
export default {
  components: {FormRegister, FormLogin},
  data() {
    return {
      tab: 0,
      items: [
        'Login'
      ],
    }
  },
}
</script>
<style scoped>

</style>